@import './fonts/fonts.css';

html,
body {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #FFFFFF !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-y: 170.89px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F2F2F2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #CCCCCC;
  border-radius: 4px;
}